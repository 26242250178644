import React, { ReactNode } from "react";
import { createUseStyles } from "react-jss";
import { FormattedMessage, useIntl } from "react-intl";
import { commonMessages } from "../../messages";
// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
const useStyles = createUseStyles({
  footer: {
    width: "100%",
    minHeight: 100,
    backgroundColor: "#FFFFFF",
  },
  childrenContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    paddingBottom: 37,
    fontSize: 11,
    fontWeight: "bold",
    textAlign: "center",
    "& a": {
      textDecoration: "none",
    },
  },
  contact: {
    opacity: 0.7,
  },
});

export const Footer = (): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <footer className={classes.footer}>
      <div className={classes.childrenContainer}>
        <p>
          <a
            href={intl.formatMessage(commonMessages.privacyLink)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {intl.formatMessage(commonMessages.footerPrivacyPolicy)}
          </a>
          {" • "}

          <a
            href={intl.formatMessage(commonMessages.termsLink)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {intl.formatMessage(commonMessages.footerTermOfUse)}
          </a>
          {" • "}
          <a
            href="https://yourpass.eu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {intl.formatMessage(commonMessages.footerAbout)}
          </a>
        </p>
        <p className={classes.contact}>
          <FormattedMessage
            id={commonMessages.footerContact.id}
            defaultMessage={commonMessages.footerContact.defaultMessage}
            values={{
              a: (chunks: ReactNode): ReactNode => (
                <a
                  href={`mailto:${intl.formatMessage(
                    commonMessages.infoEmail
                  )}`}
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </p>
        <p>
          <a href="mailto:support@yourpass.eu">support@yourpass.eu</a>
        </p>
      </div>
    </footer>
  );
};

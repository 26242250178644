interface MyWindow extends Window {
  // eslint-disable-next-line no-underscore-dangle
  _gopay: {
    checkout: (
      opt: { gatewayUrl: string; inline: boolean },
      callback: () => void
    ) => void;
  };
}

declare const window: MyWindow;

export default function payTransaction(url: string): Promise<boolean> {
  return new Promise((resolve) => {
    const pay = (): void => {
      // eslint-disable-next-line no-underscore-dangle
      window._gopay.checkout(
        {
          gatewayUrl: url,
          inline: true,
        },
        () => {
          resolve(true);
        }
      );
    };
    // eslint-disable-next-line no-underscore-dangle
    if (typeof window._gopay === "undefined") {
      const script = document.createElement("script");

      const scriptElementWithNonce: HTMLScriptElement | null = document.querySelector(
        "script[nonce]"
      );

      script.onload = (): void => {
        pay();
      };
      script.src = "https://gate.gopay.cz/gp-gw/js/embed.js";
      script.nonce = scriptElementWithNonce?.nonce;
      document.body.appendChild(script);
    } else {
      pay();
    }
  });
}

import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { createUseStyles } from "react-jss";
type ButtonProps = React.PropsWithChildren<{
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >["type"];
}>;

const useStyles = createUseStyles({
  button: {
    height: 55,
    fontSize: 18,
    fontWeight: "bold",
    borderRadius: 15,
    backgroundColor: "#9999A0",
    color: "#fff",
    border: "1px solid",
    borderColor: "#9999A0",
    paddingRight: 20,
    paddingLeft: 20,
    marginBottom: 8,
    textAlign: "center",
    textTransform: "uppercase",
    width: "100%",
    cursor: "pointer",
  },
});

export const ButtonDark = ({
  onClick,
  children,
  type,
}: ButtonProps): JSX.Element => {
  const classes = useStyles();
  return (
    <button
      onClick={onClick}
      className={classes.button}
      type={type || "button"}
    >
      {children}
    </button>
  );
};

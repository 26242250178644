import React, { FormEventHandler } from "react";
import { useIntl } from "react-intl";
import { createUseStyles } from "react-jss";
import { useCreatorContext } from "../../context/CreatorContext";
import { DesignField } from "../../types";
import {
  commonMessages,
  designFormMessages,
  fieldMessages,
} from "../../messages";
import { ColorPicker, SelectImage } from "../Input";
import { Button, ButtonOutlined } from "../Button";
import { useCallback } from "react";

const useStyles = createUseStyles({
  controlsContainer: {
    display: "flex",
    flexDirection: "row",
    "& :first-child": {
      marginRight: 20,
    },
  },
  colorsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  field: {
    "& > h4": {
      fontSize: 14,
      fontWeight: "bold",
      textTransform: "uppercase",
      marginBottom: 20,
    },
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
  },
  root: {
    width: 274,
  },
});

const DesignForm = (): JSX.Element => {
  const ctx = useCreatorContext();
  const classes = useStyles();
  const intl = useIntl();

  const handleChangeColor = (name: DesignField, value: string): void => {
    ctx.setData({ ...ctx.data, [name]: value });
  };

  const onSubmitHandler = useCallback<FormEventHandler>(
    (e) => {
      e.preventDefault();
      ctx.next();
    },
    [ctx]
  );

  return (
    <form onSubmit={onSubmitHandler}>
      <div className={classes.root}>
        <div className={classes.field}>
          <h4>{intl.formatMessage(designFormMessages.colorsTitle)}</h4>
          <div className={classes.colorsContainer}>
            <ColorPicker
              name={DesignField.backgroundColor}
              label={intl.formatMessage(
                fieldMessages[DesignField.backgroundColor]
              )}
              value={ctx.data[DesignField.backgroundColor]}
              onChange={handleChangeColor}
            />
            <ColorPicker
              name={DesignField.foregroundColor}
              label={intl.formatMessage(
                fieldMessages[DesignField.foregroundColor]
              )}
              value={ctx.data[DesignField.foregroundColor]}
              onChange={handleChangeColor}
            />
            <ColorPicker
              name={DesignField.labelColor}
              label={intl.formatMessage(fieldMessages[DesignField.labelColor])}
              value={ctx.data[DesignField.labelColor]}
              onChange={handleChangeColor}
            />
          </div>
        </div>

        <div className={classes.field}>
          <h4>{intl.formatMessage(fieldMessages[DesignField.thumbnail])}</h4>
          <SelectImage
            value={ctx.data[DesignField.thumbnail]}
            onChange={(e: string | null): void => {
              ctx.setData({ ...ctx.data, [DesignField.thumbnail]: e });
            }}
            width={180}
            height={180}
          />
        </div>

        <div className={classes.field}>
          <h4>{intl.formatMessage(fieldMessages[DesignField.logo])}</h4>
          <SelectImage
            value={ctx.data[DesignField.logo]}
            onChange={(e: string | null): void => {
              ctx.setData({ ...ctx.data, [DesignField.logo]: e });
            }}
            width={320}
            height={100}
          />
        </div>

        <div className={classes.controlsContainer}>
          <ButtonOutlined onClick={ctx.prev}>
            {intl.formatMessage(commonMessages.actionBack)}
          </ButtonOutlined>
          <Button type="submit">
            {intl.formatMessage(commonMessages.actionNext)}
          </Button>
        </div>
      </div>
    </form>
  );
};
export default DesignForm;

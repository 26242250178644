import React from "react";
import { createUseStyles } from "react-jss";
import logo from "../../assets/logo.svg";
import { LOGO_URL } from "../../config/env";

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
const useStyles = createUseStyles({
  header: {
    width: "100%",
    minHeight: 100,
    backgroundColor: "#FFFFFF",
  },
  logo: {
    paddingTop: 37,
    paddingLeft: 47,
    height: 58,
  },
  childrenContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 40,
    paddingBottom: 37,
  },
});

export const Header = (props: React.PropsWithChildren<{}>): JSX.Element => {
  const classes = useStyles();
  return (
    <header className={classes.header}>
      <a href={LOGO_URL} target="_blank" rel="noopener noreferrer">
        <img src={logo} className={classes.logo} alt="logo" />
      </a>
      <div className={classes.childrenContainer}>{props.children}</div>
    </header>
  );
};

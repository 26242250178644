import React, { useCallback, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { Pkpass, PkpassImages } from "@yourpass/react-lib";

import { BusinessCardServiceClient } from "../proto/yourpass/businesscard/v1/BusinesscardServiceClientPb";
import { BusinessCard, FormField } from "../types";
import {
  passTemplate,
  prepareImages,
  preparePassJson,
  createTransaction,
  waitFor,
} from "./helper";

import {
  CreateBusinessCardResponse,
  GetBusinessCardResponse,
  PaymentStatus,
  GetBusinessCardRequest,
} from "../proto/yourpass/businesscard/v1/businesscard_pb";
import payTransaction from "./payment";

const initForm: BusinessCard = {
  [FormField.fullName]: "",
  [FormField.organization]: "",
  [FormField.organizationUri]: "",
  [FormField.title]: "",
  [FormField.phone]: "",
  [FormField.email]: "",
  [FormField.linkedInUri]: "",
  labelColor: "rgb(0,0,0)",
  backgroundColor: "rgb(255,255,255)",
  foregroundColor: "rgb(0,0,0)",
  thumbnail: null,
  logo: null,
};

export interface CreatorContextInterface {
  data: BusinessCard;
  invoiceEmail: string;
  step: number;
  setData: (d: BusinessCard) => void;
  setInvoiceEmail: (email: string) => void;
  next: () => void;
  prev: () => void;
  reset: () => void;
  passJson: Pkpass;
  passImages: PkpassImages;
  verifyPayment: (id: string) => Promise<GetBusinessCardResponse>;
  createBusinessCard: () => Promise<GetBusinessCardResponse | null>;
}

const Context = React.createContext<CreatorContextInterface>({
  data: initForm,
  invoiceEmail: "",
  step: 0,
  passJson: passTemplate,
  passImages: {},
  verifyPayment: async () => {
    throw Error("Context is not initialized");
  },
  createBusinessCard: () => {
    throw Error("Context is not initialized");
  },
  setData: () => {
    throw Error("Context is not initialized");
  },
  setInvoiceEmail: () => {
    throw Error("Context is not initialized");
  },
  next: () => {
    throw Error("Context is not initialized");
  },
  prev: () => {
    throw Error("Context is not initialized");
  },
  reset: () => {
    throw Error("Context is not initialized");
  },
});

export const CreatorContextProvider = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>): JSX.Element => {
  const [data, setForm] = useState<BusinessCard>({ ...initForm });
  const [invoiceEmail, setInvoiceEmail] = useState("");
  const [step, setStep] = useState<number>(0);
  const [client] = useState(new BusinessCardServiceClient("", null, null));
  const [
    transaction,
    setTransaction,
  ] = useState<null | CreateBusinessCardResponse>(null);

  const setData = useCallback(
    (d: BusinessCard): void => {
      setForm({ ...data, ...d });
      setTransaction(null);
    },
    [data, setForm, setTransaction]
  );

  const next = (): void => {
    setStep(step + 1);
  };

  const prev = (): void => {
    setStep(step - 1);
  };

  const intl = useIntl();
  const passJson = preparePassJson(data, intl);
  const passImages = prepareImages(data);

  const verifyPayment = async (
    id: string
  ): Promise<GetBusinessCardResponse> => {
    const request = new GetBusinessCardRequest();
    request.setId(id);
    const response = await client.getBusinessCard(request, null);
    if (
      response.getPaymentStatus() === PaymentStatus.PENDING ||
      response.getPaymentStatus() === PaymentStatus.UNKNOWN
    ) {
      await waitFor(2000);
      return verifyPayment(id);
    }
    return response;
  };

  const createBusinessCard = async (): Promise<null | GetBusinessCardResponse> => {
    let t = transaction;
    if (t == null) {
      const req = createTransaction(data, invoiceEmail, intl);
      t = await client.createBusinessCard(req, null);
      setTransaction(t);
    }

    if (t !== null) {
      await payTransaction(t.getPaymentGatewayUri());
      const r = await verifyPayment(t.getId());
      if (r.getPaymentStatus() === PaymentStatus.OK) {
        return r;
      }
    }
    return null;
  };

  const reset = (): void => {
    setTransaction(null);
    setData({ ...initForm });
  };

  return (
    <Context.Provider
      value={{
        data,
        invoiceEmail,
        step,
        setData,
        setInvoiceEmail,
        next,
        prev,
        verifyPayment,
        createBusinessCard,
        reset,
        passImages,
        passJson,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useCreatorContext = (): CreatorContextInterface =>
  useContext<CreatorContextInterface>(Context);

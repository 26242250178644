import React from "react";

import logo from "../../assets/logo-yp.svg";
import "./Loader.css";

const Loader: React.FC = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        alt="spinner"
        style={{ animation: `spin 3s linear infinite` }}
        src={logo}
        height="30"
      />
    </div>
  );
};

export default Loader;

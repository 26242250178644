import React, { FormEventHandler, useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { createUseStyles } from "react-jss";

import { useCreatorContext } from "../../context/CreatorContext";
import { dataFieldsSchema, DataFieldsValues, FormField } from "../../types";
import { Button } from "../Button";
import { Input } from "../Input";

import { commonMessages, fieldMessages } from "../../messages";
import { ValidationError } from "yup";

const useStyles = createUseStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    minWidth: 300,
    maxWidth: 485,
    width: "100%",
    "& > :last-child": {
      marginTop: 30,
    },
  },
});

// Hackish way to determine whether a field is required
const fieldIsRequired = (schema: unknown): boolean => {
  if (!schema || typeof schema !== "object") {
    return false;
  }

  const widenedSchemaObj: { exclusiveTests?: { required?: boolean } } = {
    ...schema,
  };
  return widenedSchemaObj.exclusiveTests?.required === true;
};

const FieldsForm = (): JSX.Element => {
  const ctx = useCreatorContext();
  const classes = useStyles();
  const intl = useIntl();
  const [showErrors, setShowErrors] = useState(false);
  const [nextStepEnabled, setNextStepEnabled] = useState(false);

  const failedFields = useMemo(() => {
    const paths: string[] = [];

    try {
      dataFieldsSchema.validateSync(ctx.data, { abortEarly: false });
      setNextStepEnabled(true);
    } catch (e) {
      if (e instanceof ValidationError) {
        e.inner.forEach((validationError) => {
          if (validationError.path) {
            paths.push(validationError.path);
          }
        });
      }
      setNextStepEnabled(false);
    }

    return paths;
  }, [ctx.data, setNextStepEnabled]);

  const onSubmitHandler = useCallback<FormEventHandler>(
    (e) => {
      e.preventDefault();

      if (nextStepEnabled) {
        setShowErrors(false);
        ctx.next();
        return;
      }

      setShowErrors(true);
    },
    [ctx, nextStepEnabled, setShowErrors]
  );

  return (
    <form className={classes.form} onSubmit={onSubmitHandler}>
      {DataFieldsValues.map((field) => (
        <Input
          error={showErrors && failedFields.indexOf(field) > -1}
          key={field}
          name={field}
          value={ctx.data[field]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            if (
              e.target.name === FormField.email &&
              ctx.invoiceEmail === ctx.data.email
            ) {
              // Change the invoice email only if it was not changed from the v-card email
              ctx.setInvoiceEmail(e.target.value);
            }
            ctx.setData({ ...ctx.data, [e.target.name]: e.target.value });
          }}
          placeholder={intl.formatMessage(fieldMessages[field])}
          required={fieldIsRequired(dataFieldsSchema.fields[field])}
        />
      ))}
      <Button type="submit">
        {intl.formatMessage(commonMessages.actionNext)}
      </Button>
    </form>
  );
};

export default FieldsForm;

import React, { useState } from "react";
import { createUseStyles } from "react-jss";

export type CheckboxFieldProps = {
  label: React.ReactNode;
  value: boolean;
  onChange: (v: boolean) => void;
};

const useStyles = createUseStyles({
  checkboxField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    "& > input": {
      height: 18,
      width: 18,
      margin: 0,
      cursor: "pointer",
    },
    "& > label": {
      marginLeft: 8,
      fontSize: 12,
      cursor: "pointer",
    },
  },
});

const gen4 = (): string => {
  return Math.random().toString(16).slice(-4);
};

const generateId = (): string => {
  return ["checkbox-", gen4(), gen4(), gen4(), gen4(), gen4()].join("-");
};

export const CheckboxField = ({
  label,
  onChange,
  value,
}: CheckboxFieldProps): JSX.Element => {
  const [idAttr] = useState(() => generateId());
  const classes = useStyles();
  const handleToggle = (): void => {
    onChange(!value);
  };

  return (
    <div className={classes.checkboxField}>
      <input
        id={idAttr}
        type="checkbox"
        checked={value}
        onChange={handleToggle}
      />
      <label htmlFor={idAttr}>{label}</label>
    </div>
  );
};

import React from "react";
import { createUseStyles } from "react-jss";

interface StyleProps {
  error?: boolean;
  inputValue: string;
}

const useStyles = createUseStyles<StyleProps>({
  input: ({ error }) => ({
    height: 55,
    fontSize: 18,
    borderRadius: 15,
    borderColor: "#fff",
    borderWidth: 0,
    paddingRight: 20,
    paddingLeft: 20,
    flex: "1 1 100%",
    boxShadow: error ? "0 0 1px 1px #ee3026" : "",
  }),
  wrapper: {
    position: "relative",
    marginBottom: 8,
    display: "flex",
  },
  placeholder: ({ error, inputValue }: StyleProps) => {
    return {
      position: "absolute",
      left: 3,
      top: inputValue ? 0 : "50%",
      transform: `translate3d(0, ${inputValue ? "0" : "-50%"}, 0)`,
      fontSize: inputValue ? "0.75em" : "1.1em",
      margin: 0,
      color: error ? "#ee3026" : "#777",
      padding: "0 1em",
      transition: "all cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s",
      zIndex: 1,
    };
  },
});

export type InputProps = {
  error?: boolean;
  name: string;
  placeholder?: string;
  required?: boolean;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Input = ({
  error,
  onChange,
  placeholder,
  value,
  name,
  required,
}: InputProps): JSX.Element => {
  const classes = useStyles({ error, inputValue: value });
  return (
    <div className={classes.wrapper}>
      <input
        className={classes.input}
        onChange={onChange}
        value={value}
        name={name}
        required={required}
      />
      <span className={classes.placeholder}>
        {placeholder}
        {required ? "*" : ""}
      </span>
    </div>
  );
};

interface ConfigFromBackend {
  cardPrice: number;
  logoUrl: string;
}

declare global {
  interface Window {
    APP_CONFIG?: ConfigFromBackend;
  }
}

declare const window: Window;

export const CARD_PRICE: number = window.APP_CONFIG?.cardPrice || 0;

export const LOGO_URL: string = window.APP_CONFIG?.logoUrl || "/";

import React, { useState } from "react";
import { ColorResult, SketchPicker } from "react-color";
import { createUseStyles } from "react-jss";
import { DesignField } from "../../types";

export interface ColorPickerProps {
  name: DesignField;
  value: string;
  label: string;
  onChange: (name: DesignField, color: string) => void;
}
const useStyles = createUseStyles({
  color: {
    width: 50,
    height: 50,
    borderRadius: 15,
  },
  swatch: {
    padding: "5px",
    background: "#fff",
    borderRadius: 15,
    // boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: "inline-block",
    cursor: "pointer",
  },
  popover: {
    position: "absolute",
    zIndex: "2",
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  label: {
    marginTop: 25,
    fontSize: 13,
    fontWeight: "bolder",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

export const ColorPicker = ({
  name,
  value,
  onChange,
  label,
}: ColorPickerProps): JSX.Element => {
  const handleChangeComplete = (color: ColorResult): void => {
    onChange(name, `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b})`);
  };

  const handleChange = (color: ColorResult): void => {
    setColor(
      `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
    );
  };

  const [open, setOpen] = useState(false);
  const [color, setColor] = useState(value);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div
        className={classes.swatch}
        style={{
          background: color,
        }}
        onClick={(): void => {
          setOpen(!open);
        }}
      >
        <div className={classes.color} />
      </div>
      {open ? (
        <div className={classes.popover}>
          <div
            className={classes.cover}
            onClick={(): void => {
              setOpen(false);
            }}
          />
          <SketchPicker
            color={color}
            onChange={handleChange}
            onChangeComplete={handleChangeComplete}
          />
        </div>
      ) : null}
      <div className={classes.label}>{label}</div>
    </div>
  );
};

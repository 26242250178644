import React from "react";

import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  title: {
    fontWeight: "bold",
    fontSize: 35,
    fontFamily: "Helvetica Neue",
    margin: 0,
    paddingBottom: 20,
  },
});

export const HeaderTitle = (): JSX.Element => {
  const classes = useStyles();
  return <h1 className={classes.title}>Create your digital business card</h1>;
};

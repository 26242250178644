import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { createUseStyles } from "react-jss";
import uploadIcon from "../../assets/upload.svg";
type ButtonUploadProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >["type"];
};

const useStyles = createUseStyles({
  button: {
    cursor: "pointer",
    height: 55,
    fontSize: 18,
    fontWeight: "bold",
    borderRadius: 15,
    backgroundColor: "#FFF",
    color: "#A8A8A8",
    border: "1px solid",
    borderColor: "#FFF",
    paddingRight: 20,
    paddingLeft: 20,
    marginBottom: 8,
    textAlign: "center",
    position: "relative",
    width: "100%",
    "& > img": {
      position: "absolute",
      left: 20,
      width: 30,
      top: 10,
    },
  },
});

export const ButtonUpload = ({
  onClick,
  type,
}: ButtonUploadProps): JSX.Element => {
  const classes = useStyles();
  return (
    <button
      onClick={onClick}
      className={classes.button}
      type={type || "button"}
    >
      <img src={uploadIcon} alt={"Download"} />
      Click to upload
    </button>
  );
};

import { defineMessages } from "react-intl";
import { FormField, DesignField } from "./types";

export const fieldMessages = defineMessages({
  [FormField.fullName]: {
    id: "fieldMessages.name",
    defaultMessage: "Full name",
  },
  [FormField.linkedInUri]: {
    id: "fieldMessages.linked_in_uri",
    defaultMessage: "LinkedIn URL",
  },
  [FormField.email]: { id: "fieldMessages.email", defaultMessage: "Email" },
  [FormField.phone]: { id: "fieldMessages.phone", defaultMessage: "Phone" },
  [FormField.organization]: {
    id: "fieldMessages.organization",
    defaultMessage: "Company",
  },
  [FormField.organizationUri]: {
    id: "fieldMessages.organization_uri",
    defaultMessage: "Web",
  },
  [FormField.title]: { id: "fieldMessages.title", defaultMessage: "Title" },
  [DesignField.labelColor]: {
    id: "fieldMessages.labelColor",
    defaultMessage: "Title",
  },
  [DesignField.foregroundColor]: {
    id: "fieldMessages.foregroundColor",
    defaultMessage: "Text",
  },
  [DesignField.backgroundColor]: {
    id: "fieldMessages.backgroundColor",
    defaultMessage: "Background",
  },
  [DesignField.thumbnail]: {
    id: "fieldMessages.thumbnail",
    defaultMessage: "Upload your photo",
  },
  [DesignField.logo]: {
    id: "fieldMessages.logo",
    defaultMessage: "Upload company logo",
  },
  invoiceEmail: {
    id: "fieldMessages.invoiceEmail",
    defaultMessage: "Invoice Email",
  },
});

export const commonMessages = defineMessages({
  actionApply: {
    defaultMessage: "Apply",
    id: "commonMessages.actionApply",
  },
  actionBack: {
    defaultMessage: "Back",
    id: "commonMessages.actionBack",
  },
  actionClose: {
    defaultMessage: "Close",
    id: "commonMessages.actionClose",
  },
  actionNext: {
    defaultMessage: "Next",
    id: "commonMessages.actionNext",
  },
  headerTitle: {
    defaultMessage: "Create your digital business card",
    id: "commonMessages.headerTitle",
  },
  infoEmail: {
    defaultMessage: "info@yourpass.eu",
    id: "commonMessages.infoEmail",
  },
  footerPrivacyPolicy: {
    defaultMessage: `PRIVACY POLICY`,
    id: "commonMessages.footerPrivacyPolicy",
  },
  footerTermOfUse: {
    defaultMessage: `TERMS OF USE`,
    id: "commonMessages.footerTermOfUse",
  },
  footerContact: {
    defaultMessage: `YOUR PASS, s.r.o. | IČ: 24809888 Türkova 2319/5b, Chodov, 149 00 Praha | email: <a>info@yourpass.eu</a>`,
    id: "commonMessages.footerContact",
  },
  footerAbout: {
    defaultMessage: `ABOUT YOUR PASS`,
    id: "commonMessages.footerAbout",
  },
  privacyLink: {
    id: "commonMessages.privacyLink",
    defaultMessage: "https://digitalbusinesscardcreator.com/privacy-policy",
  },
  step0: {
    defaultMessage: "CONTENT",
    id: "commonMessages.step0",
  },
  step1: {
    defaultMessage: "COLORS & LOGO & PHOTO",
    id: "commonMessages.step1",
  },
  step2: {
    defaultMessage: "GENERATE",
    id: "commonMessages.step3",
  },
  termsLink: {
    id: "commonMessages.termsLink",
    defaultMessage: "https://digitalbusinesscardcreator.com/terms-of-use",
  },
});

export const confirmFormMessages = defineMessages({
  agreementCheckboxTitle: {
    id: "confirmFormMessages.agreementCheckboxTitle",
    defaultMessage: `I agree with <terms>Terms of Use</terms> and <privacy>Privacy Policy</privacy>`,
  },
  title: {
    id: "confirmFormMessages.title",
    defaultMessage: `Almost there!`,
  },
  info: {
    id: "confirmFormMessages.info",
    defaultMessage: `Please, double check your information and the card preview, later changes are not possible.`,
  },
  invoiceEmailMissing: {
    id: "confirmFormMessages.invoiceEmailMissing",
    defaultMessage:
      "Please enter an e-mail address on which you want to receive the invoice.",
  },
  agreementMissing: {
    id: "confirmFormMessages.agreementMissing",
    defaultMessage: "Please agree with the Terms of Use and Privacy Policy.",
  },
});

export const finishMessages = defineMessages({
  title: {
    id: "finishMessages.title",
    defaultMessage: `Thank you!`,
  },
  download: {
    id: "finishMessages.download",
    defaultMessage: `Download`,
  },
  errorCreate: {
    id: "finishMessages.errorCreate",
    defaultMessage:
      "There was an issue creating your digital business card. Please try refreshing the page or contact us at <a>info@yourpass.eu</a>.",
  },
  errorCreateTitle: {
    id: "finishMessages.errorCreateTitle",
    defaultMessage: `Error creating your card`,
  },
  errorPaymentNotFound: {
    id: "finishMessages.errorPaymentNotFound",
    defaultMessage:
      "Your payment details could not be found. Please contact us at <a>info@yourpass.eu</a>.",
  },
  errorPaymentNotFoundTitle: {
    id: "finishMessages.errorPaymentNotFoundTitle",
    defaultMessage: "Payment not found",
  },
  errorVerify: {
    id: "finishMessages.errorVerify",
    defaultMessage:
      "There was an issue verifying your payment. Please contact us at <a>info@yourpass.eu</a>.",
  },
  errorVerifyTitle: {
    id: "finishMessages.errorVerifyTitle",
    defaultMessage: `Error verifying your payment`,
  },
  info: {
    id: "finishMessages.info",
    defaultMessage: `Your business card is on its way to your <nowrap>e-mail</nowrap>.
If you wish to download it right now, scan the QR code
or click the button below.`,
  },
  loading: {
    id: "finishMessages.loading",
    defaultMessage: "Please wait while your card is being created.",
  },
  loadingTitle: {
    id: "finishMessages.loadingTitle",
    defaultMessage: "Creating your card",
  },
});

export const designFormMessages = defineMessages({
  colorsTitle: {
    id: "designFormMessages.colorsTitle",
    defaultMessage: `ADJUST COLOURS`,
  },
});

export const selectImageMessages = defineMessages({
  unselectImage: {
    defaultMessage: "Remove selected photo",
    id: "selectImageMessages.unselect",
  },
  cropperModalTicket: {
    defaultMessage: "Adjust image",
    id: "selectImageMessages.cropperModalTicket",
  },
});

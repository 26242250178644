// source: yourpass/businesscard/v1/businesscard.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.yourpass.businesscard.v1.CreateBusinessCardRequest', null, global);
goog.exportSymbol('proto.yourpass.businesscard.v1.CreateBusinessCardResponse', null, global);
goog.exportSymbol('proto.yourpass.businesscard.v1.GetBusinessCardRequest', null, global);
goog.exportSymbol('proto.yourpass.businesscard.v1.GetBusinessCardResponse', null, global);
goog.exportSymbol('proto.yourpass.businesscard.v1.PaymentStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.businesscard.v1.CreateBusinessCardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.businesscard.v1.CreateBusinessCardRequest.displayName = 'proto.yourpass.businesscard.v1.CreateBusinessCardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.businesscard.v1.CreateBusinessCardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.businesscard.v1.CreateBusinessCardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.businesscard.v1.CreateBusinessCardResponse.displayName = 'proto.yourpass.businesscard.v1.CreateBusinessCardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.businesscard.v1.GetBusinessCardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.businesscard.v1.GetBusinessCardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.businesscard.v1.GetBusinessCardRequest.displayName = 'proto.yourpass.businesscard.v1.GetBusinessCardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.businesscard.v1.GetBusinessCardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.businesscard.v1.GetBusinessCardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.businesscard.v1.GetBusinessCardResponse.displayName = 'proto.yourpass.businesscard.v1.GetBusinessCardResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.businesscard.v1.CreateBusinessCardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.businesscard.v1.CreateBusinessCardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organization: jspb.Message.getFieldWithDefault(msg, 3, ""),
    organizationUri: jspb.Message.getFieldWithDefault(msg, 4, ""),
    title: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    linkedInUri: jspb.Message.getFieldWithDefault(msg, 8, ""),
    backfield: jspb.Message.getFieldWithDefault(msg, 9, ""),
    backgroundColor: jspb.Message.getFieldWithDefault(msg, 10, 0),
    foregroundColor: jspb.Message.getFieldWithDefault(msg, 11, 0),
    labelColor: jspb.Message.getFieldWithDefault(msg, 12, 0),
    logo: msg.getLogo_asB64(),
    thumbnail: msg.getThumbnail_asB64(),
    invoiceEmail: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardRequest}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.businesscard.v1.CreateBusinessCardRequest;
  return proto.yourpass.businesscard.v1.CreateBusinessCardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.businesscard.v1.CreateBusinessCardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardRequest}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganization(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationUri(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkedInUri(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackfield(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setBackgroundColor(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setForegroundColor(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setLabelColor(value);
      break;
    case 13:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLogo(value);
      break;
    case 14:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setThumbnail(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.businesscard.v1.CreateBusinessCardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.businesscard.v1.CreateBusinessCardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganization();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrganizationUri();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLinkedInUri();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBackfield();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBackgroundColor();
  if (f !== 0) {
    writer.writeFixed32(
      10,
      f
    );
  }
  f = message.getForegroundColor();
  if (f !== 0) {
    writer.writeFixed32(
      11,
      f
    );
  }
  f = message.getLabelColor();
  if (f !== 0) {
    writer.writeFixed32(
      12,
      f
    );
  }
  f = message.getLogo_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      13,
      f
    );
  }
  f = message.getThumbnail_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      14,
      f
    );
  }
  f = message.getInvoiceEmail();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardRequest} returns this
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organization = 3;
 * @return {string}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.getOrganization = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardRequest} returns this
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string organization_uri = 4;
 * @return {string}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.getOrganizationUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardRequest} returns this
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.setOrganizationUri = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string title = 5;
 * @return {string}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardRequest} returns this
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phone = 6;
 * @return {string}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardRequest} returns this
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardRequest} returns this
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string linked_in_uri = 8;
 * @return {string}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.getLinkedInUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardRequest} returns this
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.setLinkedInUri = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string backfield = 9;
 * @return {string}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.getBackfield = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardRequest} returns this
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.setBackfield = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional fixed32 background_color = 10;
 * @return {number}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.getBackgroundColor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardRequest} returns this
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.setBackgroundColor = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional fixed32 foreground_color = 11;
 * @return {number}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.getForegroundColor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardRequest} returns this
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.setForegroundColor = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional fixed32 label_color = 12;
 * @return {number}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.getLabelColor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardRequest} returns this
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.setLabelColor = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bytes logo = 13;
 * @return {!(string|Uint8Array)}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.getLogo = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * optional bytes logo = 13;
 * This is a type-conversion wrapper around `getLogo()`
 * @return {string}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.getLogo_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLogo()));
};


/**
 * optional bytes logo = 13;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLogo()`
 * @return {!Uint8Array}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.getLogo_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLogo()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardRequest} returns this
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.setLogo = function(value) {
  return jspb.Message.setProto3BytesField(this, 13, value);
};


/**
 * optional bytes thumbnail = 14;
 * @return {!(string|Uint8Array)}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.getThumbnail = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * optional bytes thumbnail = 14;
 * This is a type-conversion wrapper around `getThumbnail()`
 * @return {string}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.getThumbnail_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getThumbnail()));
};


/**
 * optional bytes thumbnail = 14;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getThumbnail()`
 * @return {!Uint8Array}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.getThumbnail_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getThumbnail()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardRequest} returns this
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3BytesField(this, 14, value);
};


/**
 * optional string invoice_email = 15;
 * @return {string}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.getInvoiceEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardRequest} returns this
 */
proto.yourpass.businesscard.v1.CreateBusinessCardRequest.prototype.setInvoiceEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.businesscard.v1.CreateBusinessCardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.businesscard.v1.CreateBusinessCardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.businesscard.v1.CreateBusinessCardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentGatewayUri: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardResponse}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.businesscard.v1.CreateBusinessCardResponse;
  return proto.yourpass.businesscard.v1.CreateBusinessCardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.businesscard.v1.CreateBusinessCardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardResponse}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentGatewayUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.businesscard.v1.CreateBusinessCardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.businesscard.v1.CreateBusinessCardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.businesscard.v1.CreateBusinessCardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentGatewayUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardResponse} returns this
 */
proto.yourpass.businesscard.v1.CreateBusinessCardResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string payment_gateway_uri = 2;
 * @return {string}
 */
proto.yourpass.businesscard.v1.CreateBusinessCardResponse.prototype.getPaymentGatewayUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.businesscard.v1.CreateBusinessCardResponse} returns this
 */
proto.yourpass.businesscard.v1.CreateBusinessCardResponse.prototype.setPaymentGatewayUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.businesscard.v1.GetBusinessCardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.businesscard.v1.GetBusinessCardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.businesscard.v1.GetBusinessCardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.businesscard.v1.GetBusinessCardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.businesscard.v1.GetBusinessCardRequest}
 */
proto.yourpass.businesscard.v1.GetBusinessCardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.businesscard.v1.GetBusinessCardRequest;
  return proto.yourpass.businesscard.v1.GetBusinessCardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.businesscard.v1.GetBusinessCardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.businesscard.v1.GetBusinessCardRequest}
 */
proto.yourpass.businesscard.v1.GetBusinessCardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.businesscard.v1.GetBusinessCardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.businesscard.v1.GetBusinessCardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.businesscard.v1.GetBusinessCardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.businesscard.v1.GetBusinessCardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.yourpass.businesscard.v1.GetBusinessCardRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.businesscard.v1.GetBusinessCardRequest} returns this
 */
proto.yourpass.businesscard.v1.GetBusinessCardRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.businesscard.v1.GetBusinessCardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.businesscard.v1.GetBusinessCardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.businesscard.v1.GetBusinessCardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.businesscard.v1.GetBusinessCardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentStatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cardUri: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.businesscard.v1.GetBusinessCardResponse}
 */
proto.yourpass.businesscard.v1.GetBusinessCardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.businesscard.v1.GetBusinessCardResponse;
  return proto.yourpass.businesscard.v1.GetBusinessCardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.businesscard.v1.GetBusinessCardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.businesscard.v1.GetBusinessCardResponse}
 */
proto.yourpass.businesscard.v1.GetBusinessCardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.yourpass.businesscard.v1.PaymentStatus} */ (reader.readEnum());
      msg.setPaymentStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.businesscard.v1.GetBusinessCardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.businesscard.v1.GetBusinessCardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.businesscard.v1.GetBusinessCardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.businesscard.v1.GetBusinessCardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCardUri();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.yourpass.businesscard.v1.GetBusinessCardResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.businesscard.v1.GetBusinessCardResponse} returns this
 */
proto.yourpass.businesscard.v1.GetBusinessCardResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PaymentStatus payment_status = 2;
 * @return {!proto.yourpass.businesscard.v1.PaymentStatus}
 */
proto.yourpass.businesscard.v1.GetBusinessCardResponse.prototype.getPaymentStatus = function() {
  return /** @type {!proto.yourpass.businesscard.v1.PaymentStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.yourpass.businesscard.v1.PaymentStatus} value
 * @return {!proto.yourpass.businesscard.v1.GetBusinessCardResponse} returns this
 */
proto.yourpass.businesscard.v1.GetBusinessCardResponse.prototype.setPaymentStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string card_uri = 3;
 * @return {string}
 */
proto.yourpass.businesscard.v1.GetBusinessCardResponse.prototype.getCardUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.businesscard.v1.GetBusinessCardResponse} returns this
 */
proto.yourpass.businesscard.v1.GetBusinessCardResponse.prototype.setCardUri = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.yourpass.businesscard.v1.PaymentStatus = {
  UNKNOWN: 0,
  PENDING: 1,
  OK: 2,
  CANCELED: 3,
  TIMEOUT: 4,
  DECLINED_3D: 5,
  DECLINED_AC: 6,
  ERROR: 7
};

goog.object.extend(exports, proto.yourpass.businesscard.v1);

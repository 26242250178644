import * as yup from "yup";

export enum FormField {
  fullName = "fullName",
  organization = "organization",
  organizationUri = "organization_uri",
  title = "title",
  phone = "phone",
  email = "email",
  linkedInUri = "linked_in_uri",
}

export const dataFieldsSchema = yup.object({
  // Nullable and defined order is important
  // @see https://github.com/jquense/yup/issues/922
  [FormField.fullName]: yup.string().defined().nullable(false),
  [FormField.organization]: yup.string().defined().nullable(false),
  [FormField.organizationUri]: yup.string().defined().nullable(false),
  [FormField.title]: yup.string().defined().nullable(false),
  [FormField.phone]: yup.string().defined().nullable(false),
  [FormField.email]: yup.string().required().email(),
  [FormField.linkedInUri]: yup.string().defined().nullable(false),
});

export type DataFieldsSchema = yup.Asserts<typeof dataFieldsSchema>;

export const DataFieldsValues: FormField[] = [
  FormField.fullName,
  FormField.organization,
  FormField.title,
  FormField.phone,
  FormField.email,
];

export enum DesignField {
  labelColor = "labelColor",
  foregroundColor = "foregroundColor",
  backgroundColor = "backgroundColor",
  thumbnail = "thumbnail",
  logo = "logo",
}

export const DesignFieldsValues: DesignField[] = [
  DesignField.labelColor,
  DesignField.foregroundColor,
  DesignField.backgroundColor,
  DesignField.thumbnail,
  DesignField.logo,
];

export const designFieldsSchema = yup.object({
  // Nullable and defined order is important
  // @see https://github.com/jquense/yup/issues/922
  labelColor: yup.string().defined().nullable(false),
  foregroundColor: yup.string().defined().nullable(false),
  backgroundColor: yup.string().defined().nullable(false),
  thumbnail: yup.string().nullable().defined(),
  logo: yup.string().nullable().defined(),
});

export type DesignFieldsSchema = yup.Asserts<typeof designFieldsSchema>;

export const billingFieldsSchema = yup.object({
  invoiceEmail: yup.string().required().email(),
});

export type BillingFieldsSchema = yup.Asserts<typeof billingFieldsSchema>;

type BusinessCardSchemas = DataFieldsSchema & DesignFieldsSchema;

export type BusinessCard = DataFieldsSchema &
  DesignFieldsSchema &
  {
    [KEY in keyof BusinessCardSchemas]: BusinessCardSchemas[KEY];
  };

import "cropperjs/dist/cropper.css";

import React, { ChangeEvent, MouseEventHandler, useRef, useState } from "react";
import Cropper from "react-cropper";
import { createUseStyles } from "react-jss";

import { Button, ButtonDark, ButtonOutlined } from "../Button";
import { ButtonUpload } from "../Button/ButtonUpload";
import { commonMessages, selectImageMessages } from "../../messages";
import { useIntl } from "react-intl";
import { useCallback } from "react";

const useStyles = createUseStyles({
  input: {
    display: "none",
  },
  modalContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 3000,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  modal: {
    zIndex: 3000,
    minHeight: 400,
    minWidth: 400,
    maxHeight: 600,
    maxWidth: 800,
    backgroundColor: "#fff",
    padding: 20,
    borderRadius: 15,
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
  },
  modalControls: {
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "& > *": {
      width: "30%",
    },
  },
  removeButton: {
    fontSize: 12,
  },
});

const toBase64 = (file: File): Promise<string | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => resolve(reader.result as string | null);
    reader.onerror = reject;
  });

const resizeCanvas = (
  canvas: HTMLCanvasElement,
  width: number,
  height: number
): Promise<HTMLCanvasElement> => {
  return new Promise((resolve, reject) => {
    const img = document.createElement("img");
    img.onload = (): void => {
      const resized = document.createElement("canvas");
      const ctx = resized.getContext("2d");
      if (ctx != null) {
        resized.height = height;
        resized.width = width;
        ctx.drawImage(img, 0, 0, width, height);
        resolve(resized);
      } else {
        reject(new Error("could not resize canvas"));
      }
    };
    img.onerror = reject;
    img.src = canvas.toDataURL();
  });
};

export interface SelectImageProps {
  value: string | null;
  onChange: (base64: string | null) => void;
  width: number;
  height: number;
}
export const SelectImage = ({
  value,
  height,
  width,
  onChange,
}: SelectImageProps): JSX.Element => {
  const classes = useStyles();
  const [cropper, setCropper] = useState<Cropper | undefined>(undefined);
  const [base64, setBase64] = useState<string | null>(value);
  const [open, setOpen] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const intl = useIntl();

  const handleClear = useCallback((): void => {
    setBase64(null);
    onChange(null);
  }, [onChange, setBase64]);

  const handleClickOpenSelect = (): void => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleSelectFile = useCallback(
    async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
      if (e.target.files) {
        const f = e.target.files[0];
        const b = await toBase64(f);
        setBase64(b);
        setOpen(true);
      }
    },
    [setBase64, setOpen]
  );

  const getCropData = useCallback<MouseEventHandler>(async () => {
    if (typeof cropper !== "undefined") {
      const canvas = cropper.getCroppedCanvas();
      const resized = await resizeCanvas(canvas, width, height);
      onChange(resized.toDataURL("image/png"));
      setOpen(false);
    }
  }, [cropper, height, onChange, setOpen, width]);

  const ratio = width / height;

  return (
    <>
      {base64 != null ? (
        <ButtonDark onClick={handleClear}>
          <span className={classes.removeButton}>
            {intl.formatMessage(selectImageMessages.unselectImage)}
          </span>
        </ButtonDark>
      ) : (
        <ButtonUpload onClick={handleClickOpenSelect} />
      )}
      <input
        className={classes.input}
        type="file"
        ref={inputRef}
        onChange={handleSelectFile}
        accept="image/*"
      />
      {open && (
        <div className={classes.modalContainer}>
          <div className={classes.modal}>
            <h3>
              {intl.formatMessage(selectImageMessages.cropperModalTicket)}
            </h3>
            {base64 && (
              <Cropper
                style={{ height: 400, width: "100%" }}
                initialAspectRatio={ratio}
                aspectRatio={ratio}
                preview=".img-preview"
                // viewMode={1}
                guides
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background
                responsive
                zoomable
                scalable
                //autoCropArea={1}
                checkOrientation={false}
                src={base64}
                onInitialized={(instance: Cropper): void => {
                  setCropper(instance);
                }}
              />
            )}
            <div className={classes.modalControls}>
              <ButtonOutlined
                onClick={(): void => {
                  setBase64(null);
                  setOpen(false);
                }}
              >
                {intl.formatMessage(commonMessages.actionClose)}
              </ButtonOutlined>
              <Button onClick={getCropData}>
                {intl.formatMessage(commonMessages.actionApply)}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

import React, {
  FormEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { createUseStyles } from "react-jss";
import * as yup from "yup";

import { useCreatorContext } from "../../context/CreatorContext";
import { Button, ButtonOutlined } from "../Button";
import { CheckboxField } from "../Input";
import { commonMessages, confirmFormMessages } from "../../messages";
import { FormattedMessage, useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { Input } from "../Input";
import { PaymentStatus } from "../../proto/yourpass/businesscard/v1/businesscard_pb";
import { fieldMessages } from "../../messages";

const invoiceEmailSchema = yup.string().required().email();

const useStyles = createUseStyles({
  agreementfield: {
    paddingTop: "1em",
    paddingBottom: "5em",
  },
  controlsContainer: {
    display: "flex",
    flexDirection: "row",
    "& :first-child": {
      marginRight: 20,
    },
  },
  colorsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  errorMessage: {
    color: "#ee3026",
  },
  root: {
    width: 274,
  },
  header: {
    fontSize: 25,
    fontWeight: "bold",
    paddingBottom: 30,
  },
  body1: {
    fontSize: 16,
    paddingBottom: 30,
  },
});
const ConfirmForm = (): JSX.Element => {
  const ctx = useCreatorContext();
  const classes = useStyles();
  const [agree, setAgree] = useState(false);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [touched, setTouched] = useState(false);

  const proceedEnabled = useMemo(() => {
    if (!agree) {
      return false;
    }

    try {
      invoiceEmailSchema.validateSync(ctx.invoiceEmail);
    } catch (e) {
      return false;
    }

    return true;
  }, [agree, ctx.invoiceEmail]);

  const onSubmitHandler = useCallback<FormEventHandler>(
    async (e) => {
      e.preventDefault();

      setTouched(true);

      if (!proceedEnabled) {
        return;
      }

      try {
        const response = await ctx.createBusinessCard();
        if (
          response !== null &&
          response.getPaymentStatus() === PaymentStatus.OK
        ) {
          enqueueSnackbar("Payment successful", { variant: "success" });
          history.push(`/finish/${response.getId()}`);
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar("Unsuccessful transaction", { variant: "error" });
      }
    },
    [ctx, enqueueSnackbar, history, proceedEnabled, setTouched]
  );

  return (
    <form className={classes.root} onSubmit={onSubmitHandler}>
      <h2 className={classes.header}>
        {intl.formatMessage(confirmFormMessages.title)}
      </h2>
      <p className={classes.body1}>
        {intl.formatMessage(confirmFormMessages.info)}
      </p>
      <Input
        name="invoice_email"
        value={ctx.invoiceEmail}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          ctx.setInvoiceEmail(e.target.value);
        }}
        placeholder={intl.formatMessage(fieldMessages.invoiceEmail)}
        required
      />
      <div className={classes.agreementfield}>
        <CheckboxField
          value={agree}
          onChange={setAgree}
          label={
            <FormattedMessage
              id={confirmFormMessages.agreementCheckboxTitle.id}
              defaultMessage={
                confirmFormMessages.agreementCheckboxTitle.defaultMessage
              }
              values={{
                privacy: (chunks: ReactNode): ReactNode => {
                  return (
                    <a
                      href={intl.formatMessage(commonMessages.privacyLink)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {chunks}
                    </a>
                  );
                },
                terms: (chunks: ReactNode): ReactNode => {
                  return (
                    <a
                      href={intl.formatMessage(commonMessages.termsLink)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {chunks}
                    </a>
                  );
                },
              }}
            />
          }
        />
      </div>
      {touched && (
        <div>
          {!agree && (
            <p className={classes.errorMessage}>
              {intl.formatMessage(confirmFormMessages.agreementMissing)}
            </p>
          )}
          {ctx.invoiceEmail.trim() === "" && (
            <p className={classes.errorMessage}>
              {intl.formatMessage(confirmFormMessages.invoiceEmailMissing)}
            </p>
          )}
        </div>
      )}
      <div className={classes.controlsContainer}>
        <ButtonOutlined onClick={ctx.prev}>Back</ButtonOutlined>
        <Button type="submit">Proceed</Button>
      </div>
    </form>
  );
};

export default ConfirmForm;

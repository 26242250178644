import React from "react";
import Bwipjs from "bwip-js";

export interface QrCodeProps {
  size: number;
  value: string;
}

export const QrCode = ({ size, value }: QrCodeProps): JSX.Element => {
  const canvas = React.useRef(null);
  React.useEffect(() => {
    if (canvas.current) {
      try {
        Bwipjs.toCanvas(canvas.current, {
          width: size,
          height: size,
          //padding: 3 * theme.scale,
          bcid: "qrcode", // Barcode type
          text: value, // Text to encode
          scale: 1, // 3x scaling factor
          textxAlign: "center", // Always good to set this
          //barcolor: props.disabled ? 'eeeeee' : '000000',
        });
      } catch (e) {
        console.error(e);
      }
    }
  }, [value, size]);
  return (
    <canvas
      ref={canvas}
      width={size}
      height={size}
      style={{ width: size, height: size }}
    />
  );
};

import React from "react";
import { Header, HeaderTitle, Stepper } from "../components";
import { createUseStyles } from "react-jss";
import { useCreatorContext } from "../context/CreatorContext";
import { Preview } from "../components/Preview";
import { FieldsForm, DesignForm, ConfirmForm } from "../components/Forms";
import { useIntl } from "react-intl";
import { commonMessages } from "../messages";
import { CARD_PRICE } from "../config/env";

const useStyles = createUseStyles({
  title: {
    fontWeight: "bold",
    fontSize: 35,
    fontFamily: "Helvetica Neue",
    margin: 0,
    paddingBottom: 16,
  },
  logo: {
    paddingTop: 37,
    paddingLeft: 47,
    height: 58,
  },
  childrenContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 40,
  },
  container: {
    width: "100%",
    minWidth: 336,
    textAlign: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap-reverse",
    "& > *": {
      margin: 40,
      marginTop: 60,
      marginBottom: 60,
    },
  },
  controls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: 400,
  },
  preview: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const formatPrice = (price: number): string => {
  return (price / 100).toFixed(2);
};

export const Creator = (): JSX.Element => {
  const classes = useStyles();
  const ctx = useCreatorContext();
  const intl = useIntl();
  return (
    <>
      <Header>
        <HeaderTitle />
        <Stepper
          step={ctx.step}
          steps={[
            intl.formatMessage(commonMessages.step0),
            intl.formatMessage(commonMessages.step1),
            intl.formatMessage(commonMessages.step2),
          ]}
        />
      </Header>
      <div className={classes.container}>
        <div className={classes.row}>
          <div className={classes.controls}>
            <h1>Price {formatPrice(CARD_PRICE)} EUR</h1>
            {ctx.step === 0 && <FieldsForm />}
            {ctx.step === 1 && <DesignForm />}
            {ctx.step === 2 && <ConfirmForm />}
          </div>
          <div className={classes.preview}>
            <Preview />
          </div>
        </div>
      </div>
    </>
  );
};

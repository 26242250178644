import React from "react";
import { createUseStyles } from "react-jss";
import { IntlProvider } from "react-intl";
import { SnackbarProvider } from "notistack";
import { RouteComponentProps } from "react-router";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Finish, Creator } from "./screens";
import { CreatorContextProvider } from "./context/CreatorContext";
import { Footer } from "./components";

const useStyles = createUseStyles({
  app: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  content: { flexGrow: 1 },
});

const App = (): JSX.Element => {
  const classes = useStyles();
  return (
    <IntlProvider messages={{}} locale="en" defaultLocale="en">
      <SnackbarProvider maxSnack={3}>
        <CreatorContextProvider>
          <div className={classes.app}>
            <div className={classes.content}>
              <Router>
                <Switch>
                  <Route
                    exact
                    path="/finish/:passId?"
                    render={({
                      match,
                    }: RouteComponentProps<{
                      passId: string;
                    }>): JSX.Element => <Finish id={match.params.passId} />}
                  />

                  <Route path="/">
                    <Creator />
                  </Route>
                </Switch>
              </Router>
            </div>
            <Footer />
          </div>
        </CreatorContextProvider>
      </SnackbarProvider>
    </IntlProvider>
  );
};

export default App;

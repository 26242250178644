import {
  BusinessCard,
  DesignField,
  DesignFieldsValues,
  FormField,
  DataFieldsValues,
} from "../types";
import { IntlShape } from "react-intl";
import {
  jsonTemplateToPassJson,
  Pkpass,
  PkpassImages,
  Template,
} from "@yourpass/react-lib";
import { fieldMessages } from "../messages";
import template from "./template.json";
import { CreateBusinessCardRequest } from "../proto/yourpass/businesscard/v1/businesscard_pb";

const newLine = "\\n";

export const passTemplate = jsonTemplateToPassJson(
  (template as Template).jsonTemplate,
  (template as Template).languages[0]
);

const createBackfield = (data: BusinessCard, intl: IntlShape): string => {
  const backfields: string[] = [];
  DataFieldsValues.forEach((name) => {
    if (data[name]) {
      backfields.push(
        `${intl.formatMessage(fieldMessages[name])}: ${data[name]}`
      );
    }
  });

  return backfields.join(newLine);
};

export const preparePassJson = (
  data: BusinessCard,
  intl: IntlShape
): Pkpass => {
  let pass = { ...passTemplate };
  const v: Record<string, string | null> = {};

  let all = "";
  DataFieldsValues.forEach((name) => {
    v[name] = data[name];
    if (v[name]) {
      all =
        all +
        `${intl.formatMessage(fieldMessages[name])}: ${v[name]}${newLine}`;
    }
  });

  DesignFieldsValues.forEach((k) => {
    v[k] = data[k];
  });

  v.backfield = createBackfield(data, intl);

  v.vcard = "Do not scan me now!!!";

  let passStr = JSON.stringify(pass);

  Object.keys(v).forEach((k: string) => {
    if (v[k] !== null) passStr = passStr.replaceAll(`#{${k}}`, `${v[k]}`);
  });
  pass = JSON.parse(passStr);
  return pass;
};

export const prepareImages = (data: BusinessCard): PkpassImages => {
  const images: PkpassImages = {};
  if (data[DesignField.thumbnail] != null) {
    images.thumbnail = data[DesignField.thumbnail] as string;
    images.logo = data[DesignField.logo] as string;
  }
  return images;
};

const rgbaToNumber = (rgb: string): number => {
  const rgbArr = rgb.replace(/[^\d,]/g, "").split(",");

  // eslint-disable-next-line no-bitwise
  const r = parseInt(rgbArr[0], 10) & 0xff;
  // eslint-disable-next-line no-bitwise
  const g = parseInt(rgbArr[1], 10) & 0xff;
  // eslint-disable-next-line no-bitwise
  const b = parseInt(rgbArr[2], 10) & 0xff;

  // eslint-disable-next-line no-bitwise
  return (r << 16) + (g << 8) + b;
};

export const createTransaction = (
  form: BusinessCard,
  invoiceEmail: string,
  intl: IntlShape
): CreateBusinessCardRequest => {
  const request = new CreateBusinessCardRequest();
  request.setBackgroundColor(rgbaToNumber(form[DesignField.backgroundColor]));
  request.setForegroundColor(rgbaToNumber(form[DesignField.foregroundColor]));
  request.setLabelColor(rgbaToNumber(form[DesignField.labelColor]));

  const logo = form[DesignField.logo];
  if (logo !== null) {
    request.setLogo(dataURLToBase64(logo));
  }

  const thumbnail = form[DesignField.thumbnail];
  if (thumbnail !== null) {
    request.setThumbnail(dataURLToBase64(thumbnail));
  }

  request.setInvoiceEmail(invoiceEmail || form[FormField.email]);

  request.setName(form[FormField.fullName]);
  request.setOrganization(form[FormField.organization]);
  request.setOrganizationUri(form[FormField.organizationUri]);
  request.setTitle(form[FormField.title]);
  request.setPhone(form[FormField.phone]);
  request.setEmail(form[FormField.email]);
  request.setLinkedInUri(form[FormField.linkedInUri]);
  request.setBackfield(
    // Yep yep... not my code, washing my hands of newlines
    createBackfield(form, intl).replaceAll("\\n", "\n")
  );

  return request;
};

export const waitFor = async (time: number): Promise<unknown> => {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
};

export const dataURLToBase64 = (data: string): string => {
  return data.split(",")[1];
};

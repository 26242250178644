import React, { useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSnackbar } from "notistack";
import { Button, Header, QrCode } from "../components";
import { useCreatorContext } from "../context/CreatorContext";
import { Loader } from "../components/Loader";
import { Preview } from "../components/Preview";
import { GetBusinessCardResponse } from "../proto/yourpass/businesscard/v1/businesscard_pb";
import { commonMessages, finishMessages } from "../messages";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";

const useStyles = createUseStyles({
  title: {
    fontWeight: "bold",
    fontSize: 35,
    fontFamily: "Helvetica Neue",
    margin: 0,
    paddingBottom: 16,
  },
  logo: {
    paddingTop: 37,
    paddingLeft: 47,
    height: 58,
  },
  childrenContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 40,
  },
  container: {
    width: "100%",
    minWidth: 336,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap-reverse",
    "& > *": {
      margin: 40,
      marginTop: 60,
      marginBottom: 60,
    },
  },
  controls: {
    width: 336,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  header: {
    fontSize: 25,
    fontWeight: "bold",
    paddingBottom: 30,
  },
  body1: {
    fontSize: 16,
    paddingBottom: 30,
  },
  qrCodeContainer: {
    padding: 5,
    borderRadius: 15,
    backgroundColor: "#fff",
    width: 166,
    height: 166,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 60,
  },
  btnContainer: {
    width: 166,
  },
  unwrappableText: {
    whiteSpace: "nowrap",
  },
});

interface FinishProps {
  id: string;
}

enum ErrorType {
  NOT_FOUND,
  PAYMENT,
  CREATE_ERROR,
}

export const Finish = (props: FinishProps): JSX.Element => {
  const { id } = props;
  const classes = useStyles();
  const ctx = useCreatorContext();
  const [
    response,
    setResponse,
  ] = React.useState<GetBusinessCardResponse | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorType | null>(null);
  const { location } = useHistory();
  const intl = useIntl();

  const showPreview = useMemo<boolean>(() => {
    return !!(response && ctx.invoiceEmail && ctx.invoiceEmail.trim() !== "");
  }, [ctx, response]);

  React.useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const statusParam = params.get("status");
      if (statusParam !== null) {
        const sanitizesStatus = statusParam.trim();
        switch (sanitizesStatus) {
          case "error-not-found":
            setError(ErrorType.NOT_FOUND);
            break;
          case "error-payment":
            setError(ErrorType.PAYMENT);
            break;
          default:
            setError(ErrorType.CREATE_ERROR);
        }
        return;
      }
    }

    setLoading(true);

    let canceled = false;
    const getStatus = async (): Promise<void> => {
      const r = await ctx.verifyPayment(id);
      if (!canceled) {
        setResponse(r);
      }
    };
    getStatus()
      .catch((e: Error) => {
        setError(ErrorType.CREATE_ERROR);
        enqueueSnackbar(e.message, { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
    return (): void => {
      canceled = true;
      setLoading(false);
    };
  }, [id, ctx, enqueueSnackbar, location, setError, setLoading]);

  const Title = useMemo(() => {
    if (loading) {
      return intl.formatMessage(finishMessages.loadingTitle);
    }

    if (error === null) {
      return intl.formatMessage(finishMessages.title);
    }

    switch (error) {
      case ErrorType.PAYMENT:
        return intl.formatMessage(finishMessages.errorVerifyTitle);
      case ErrorType.NOT_FOUND:
        return intl.formatMessage(finishMessages.errorPaymentNotFoundTitle);
      default:
        return intl.formatMessage(finishMessages.errorCreateTitle);
    }
  }, [error, intl, loading]);

  const Body = useMemo(() => {
    if (loading) {
      return intl.formatMessage(finishMessages.loading);
    }

    if (error === null) {
      return intl.formatMessage(finishMessages.info, {
        nowrap: (chunks) => (
          <span className={classes.unwrappableText}>{chunks}</span>
        ),
      });
    }

    const anchor = (chunks: React.ReactNode): React.ReactNode => {
      return (
        <a
          href={`mailto:${intl.formatMessage(
            commonMessages.infoEmail
          )}?subject=BCC issue: no payment ID`}
        >
          {chunks}
        </a>
      );
    };

    switch (error) {
      case ErrorType.PAYMENT:
        return intl.formatMessage(finishMessages.errorVerify, {
          a: anchor,
        });
      case ErrorType.NOT_FOUND:
        return intl.formatMessage(finishMessages.errorPaymentNotFound, {
          a: anchor,
        });
      default:
        return intl.formatMessage(finishMessages.errorCreate, {
          a: anchor,
        });
    }
  }, [classes.unwrappableText, error, intl, loading]);

  return (
    <React.Fragment>
      <Header />
      <div className={classes.container}>
        <div className={classes.row}>
          <div className={classes.controls}>
            <h2 className={classes.header}>{Title}</h2>
            {loading && <Loader />}
            <p className={classes.body1}>{Body}</p>
            {response && (
              <>
                <div className={classes.qrCodeContainer}>
                  <QrCode size={150} value={response.getCardUri()} />
                </div>
                <div className={classes.btnContainer}>
                  <Button
                    onClick={(): void => {
                      window.open(response.getCardUri(), "_blank");
                    }}
                    type="button"
                  >
                    {intl.formatMessage(finishMessages.download)}
                  </Button>
                </div>
              </>
            )}
          </div>
          {showPreview && <Preview />}
        </div>
      </div>
    </React.Fragment>
  );
};

import React from "react";
import { PkpassPhonePreview } from "@yourpass/react-lib";
import { useCreatorContext } from "../../context/CreatorContext";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
  pkPassWrapper: {
    textAlign: "initial",
  },
}));
export const Preview = (): JSX.Element => {
  const { passJson, passImages } = useCreatorContext();
  const styles = useStyles();

  return (
    <div className={styles.pkPassWrapper}>
      <PkpassPhonePreview
        pass={passJson}
        images={passImages}
        scale={0.8}
        scanDisabled={true}
      />
    </div>
  );
};

import React from "react";

export interface Props {
  step: number;
  steps: [string, string, string];
}

export const Stepper = ({ step, steps }: Props): JSX.Element => (
  <svg
    height={49}
    viewBox="0 0 470 49"
    style={{ width: "100%", maxWidth: 470 }}
  >
    <defs>
      <style>
        {
          ".a,.b{fill:none;stroke-width:2px;}.a{stroke:#0d2d6a;}.b{stroke:#d2d2e0;}.c,.e{fill:#0d2d6a;}.d{fill:#d2d2e0;}.e,.f{font-size:11px;}.e{font-family:HelveticaNeue-Bold, Helvetica Neue;font-weight:700;}.f{fill:#595962;font-family:HelveticaNeue, Helvetica Neue;}"
        }
      </style>
    </defs>
    <g transform="translate(-717 -189)">
      <line
        className={step > 0 ? "a" : "b"}
        x2={189}
        transform="translate(752.5 200.5)"
      />
      <line
        className={step > 1 ? "a" : "b"}
        x2={189}
        transform="translate(941.5 200.5)"
      />
      <circle
        className="c"
        cx={11}
        cy={11}
        r={11}
        transform="translate(736 189)"
      />
      <circle
        className={step > 0 ? "c" : "d"}
        cx={11}
        cy={11}
        r={11}
        transform="translate(930 189)"
      />
      <circle
        className={step > 1 ? "c" : "d"}
        cx={11}
        cy={11}
        r={11}
        transform="translate(1124 189)"
      />
      <text className="e" transform="translate(717 236)">
        <tspan x={0} y={0}>
          {steps[0]}
        </tspan>
      </text>
      <text className={step > 0 ? "e" : "f"} transform="translate(874 236)">
        <tspan x={0} y={0}>
          {steps[1]}
        </tspan>
      </text>
      <text className={step > 1 ? "e" : "f"} transform="translate(1103 236)">
        <tspan x={0} y={0}>
          {steps[2]}
        </tspan>
      </text>
    </g>
  </svg>
);

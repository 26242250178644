/**
 * @fileoverview gRPC-Web generated client stub for yourpass.businesscard.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as yourpass_businesscard_v1_businesscard_pb from '../../../yourpass/businesscard/v1/businesscard_pb';


export class BusinessCardServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateBusinessCard = new grpcWeb.AbstractClientBase.MethodInfo(
    yourpass_businesscard_v1_businesscard_pb.CreateBusinessCardResponse,
    (request: yourpass_businesscard_v1_businesscard_pb.CreateBusinessCardRequest) => {
      return request.serializeBinary();
    },
    yourpass_businesscard_v1_businesscard_pb.CreateBusinessCardResponse.deserializeBinary
  );

  createBusinessCard(
    request: yourpass_businesscard_v1_businesscard_pb.CreateBusinessCardRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_businesscard_v1_businesscard_pb.CreateBusinessCardResponse>;

  createBusinessCard(
    request: yourpass_businesscard_v1_businesscard_pb.CreateBusinessCardRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: yourpass_businesscard_v1_businesscard_pb.CreateBusinessCardResponse) => void): grpcWeb.ClientReadableStream<yourpass_businesscard_v1_businesscard_pb.CreateBusinessCardResponse>;

  createBusinessCard(
    request: yourpass_businesscard_v1_businesscard_pb.CreateBusinessCardRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: yourpass_businesscard_v1_businesscard_pb.CreateBusinessCardResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.businesscard.v1.BusinessCardService/CreateBusinessCard',
        request,
        metadata || {},
        this.methodInfoCreateBusinessCard,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.businesscard.v1.BusinessCardService/CreateBusinessCard',
    request,
    metadata || {},
    this.methodInfoCreateBusinessCard);
  }

  methodInfoGetBusinessCard = new grpcWeb.AbstractClientBase.MethodInfo(
    yourpass_businesscard_v1_businesscard_pb.GetBusinessCardResponse,
    (request: yourpass_businesscard_v1_businesscard_pb.GetBusinessCardRequest) => {
      return request.serializeBinary();
    },
    yourpass_businesscard_v1_businesscard_pb.GetBusinessCardResponse.deserializeBinary
  );

  getBusinessCard(
    request: yourpass_businesscard_v1_businesscard_pb.GetBusinessCardRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_businesscard_v1_businesscard_pb.GetBusinessCardResponse>;

  getBusinessCard(
    request: yourpass_businesscard_v1_businesscard_pb.GetBusinessCardRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: yourpass_businesscard_v1_businesscard_pb.GetBusinessCardResponse) => void): grpcWeb.ClientReadableStream<yourpass_businesscard_v1_businesscard_pb.GetBusinessCardResponse>;

  getBusinessCard(
    request: yourpass_businesscard_v1_businesscard_pb.GetBusinessCardRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: yourpass_businesscard_v1_businesscard_pb.GetBusinessCardResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.businesscard.v1.BusinessCardService/GetBusinessCard',
        request,
        metadata || {},
        this.methodInfoGetBusinessCard,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.businesscard.v1.BusinessCardService/GetBusinessCard',
    request,
    metadata || {},
    this.methodInfoGetBusinessCard);
  }

}

